import { Vue, Component } from 'vue-property-decorator';
import { Language } from '@/i18n';

import { requireLegalBanner } from '../../../../whitelabel.config';

@Component({})

export default class BannerLegal extends Vue {
  requireLegalBanner = requireLegalBanner;

  get imgDesktopURL(): any {
    const imageEn = require('@/assets/images/bannerLegal/banner-en-desktop.jpg');
    const imageNl = require('@/assets/images/bannerLegal/banner-nl-desktop.jpg');

    switch ((this.$i18n.locale as Language)) {
      case 'nl':
        return imageNl;
      case 'en':
        return imageEn;
      default: return imageNl;
    }
  }

  get imgMobileURL(): any {
    const imageEn = require('@/assets/images/bannerLegal/banner-en-mobile.jpg');
    const imageNl = require('@/assets/images/bannerLegal/banner-nl-mobile.jpg');

    switch ((this.$i18n.locale as Language)) {
      case 'nl':
        return imageNl;
      case 'en':
        return imageEn;
      default: return imageNl;
    }
  }
}
